import ActionTypes from '../actions/actionTypes.js';
import { initialState } from './initialState.js';

const locationReducer = (state = initialState.location, action) => {
  switch (action.type) {
    case ActionTypes.location.CREATE_ACTION_LOCATION:
      return {
        ...state,
        location: initialState.location.location,
      };
    case ActionTypes.location.UPDATE_ACTION_LOCATION:
      return {
        ...state,
        location: {},
        getById: {},
      };
    case ActionTypes.location.GETBYID_ACTION_LOCATION:
      return {
        ...state,
        getById: action.payload.data,
      };
    case ActionTypes.location.GETALL_ACTION_LOCATION:
      return {
        ...state,
        getAll: action.payload.data,
      };
    case ActionTypes.location.GET_BY_USER_AREA_LOCATION:
      return {
        ...state,
        branches: action.payload,
      };
    case ActionTypes.location.HANDLE_ERROR_LOCATION:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.location.HANDLE_VALIDATION_LOCATION:
      return {
        ...state,
        validationMessage: action.payload,
      };
    case ActionTypes.location.HandleRequest:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default locationReducer;
