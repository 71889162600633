import ActionTypes from '../actions/actionTypes.js';
import { initialState } from './initialState.js';

const paymentReducer = (state = initialState.payment, action) => {
  switch (action.type) {
    case ActionTypes.payment.GET_INSTALLMENT_ACTION:
      return {
        ...state,
        installment: action.payload.data,
      };
    case ActionTypes.payment.ON_PAYMENT_ACTION:
      return {
        ...state,
        callBackContent: action.payload.data,
      };
    case ActionTypes.payment.HANDLE_ERROR_PAYMENT:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.payment.HANDLE_VALIDATION_PAYMENT:
      return {
        ...state,
        validationMessage: action.payload,
      };
    case ActionTypes.payment.RESULT_PAYMENT:
      return {
        ...state,
        resultMessage: action.payload.data,
      };
    case ActionTypes.payment.RESET_STATE:
      return initialState.payment;
    default:
      return state;
  }
};

export default paymentReducer;
