import ActionTypes from '../actions/actionTypes.js';
import { initialState } from './initialState.js';

const meetingReducer = (state = initialState.meeting, action) => {
  switch (action.type) {
    case ActionTypes.meeting.Create:
      return {
        ...state,
        meeting: initialState.meeting.meeting,
      };
    case ActionTypes.meeting.Update:
      return {
        ...state,
        meeting: {},
        getById: [],
      };
    case ActionTypes.meeting.GetMeetingsBySchemaId:
      return {
        ...state,
        getMeetingsBySchemaId: action.payload,
      };
    case ActionTypes.meeting.HandleError:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.meeting.HandleValidation:
      return {
        ...state,
        validationMessage: action.payload,
      };
    case ActionTypes.meeting.HandleRequest:
      return {
        ...state,
        loading: {
          ...state.loading,
          ...action.payload,
        },
      };
    case ActionTypes.meeting.CleanList:
      if (action.payload && typeof action.payload === 'string') {
        return {
          ...state,
          [action.payload]: initialState.meeting[action.payload],
        };
      }
      return {
        ...initialState.meeting,
      };
    default:
      return state;
  }
};

export default meetingReducer;
