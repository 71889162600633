import ActionTypes from '../actions/actionTypes.js';
import { initialState } from './initialState.js';

const campaignReducer = (state = initialState.campaign, action) => {
  switch (action.type) {
    case ActionTypes.campaign.Create:
      return {
        ...state,
        campaign: initialState.banner.banner,
      };
    case ActionTypes.campaign.Update:
      return {
        ...state,
        campaign: initialState.banner.banner,
        getById: {},
      };
    case ActionTypes.campaign.GetAll:
      return {
        ...state,
        getAll: action.payload,
      };
    case ActionTypes.campaign.GetById:
      return {
        ...state,
        getById: action.payload,
      };
    case ActionTypes.campaign.GetDashbordBanner:
      return {
        ...state,
        dashboardBanner: action.payload,
      };
    case ActionTypes.campaign.HandleError:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.campaign.HandleValidation:
      return {
        ...state,
        validationMessage: action.payload,
      };
    case ActionTypes.campaign.CleanList:
      return {
        ...state,
        campaign: initialState.campaign.campaign,
        getById: {},
        getAll: [],
        getActiveBannerByZone: {},
      };
    default:
      return state;
  }
};

export default campaignReducer;
