import ActionTypes from '../actions/actionTypes.js';
import { initialState } from './initialState.js';

const managerReducer = (state = initialState.manager, action) => {
  switch (action.type) {
    case ActionTypes.manager.CREATE_ACTION_MANAGER:
      return {
        ...state,
        manager: initialState.manager.manager,
      };
    case ActionTypes.manager.UPDATE_ACTION_MANAGER:
      return {
        ...state,
        manager: {},
        getById: [],
      };
    case ActionTypes.manager.GETBYID_ACTION_MANAGER:
      return {
        ...state,
        getById: action.payload.data,
      };
    case ActionTypes.manager.GETALL_ACTION_MANAGER:
      return {
        ...state,
        getAll: action.payload.data,
      };
    case ActionTypes.manager.HANDLE_ERROR_MANAGER:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.manager.HANDLE_VALIDATION_MANAGER:
      return {
        ...state,
        validationMessage: action.payload,
      };
    default:
      return state;
  }
};

export default managerReducer;
