import ActionTypes from '../actions/actionTypes.js';
import { initialState } from './initialState.js';

const speakerReducer = (state = initialState.speaker, action) => {
  switch (action.type) {
    case ActionTypes.speaker.CREATE_ACTION_SPEAKER:
      return {
        ...state,
        speaker: initialState.speaker.speaker,
      };
    case ActionTypes.speaker.UPDATE_ACTION_SPEAKER:
      return {
        ...state,
        speaker: [],
        getById: [],
      };
    case ActionTypes.speaker.GETBYID_ACTION_SPEAKER:
      return {
        ...state,
        getById: action.payload.data,
      };
    case ActionTypes.speaker.GETALL_ACTION_SPEAKER:
      return {
        ...state,
        getAll: action.payload.data,
      };
    case ActionTypes.speaker.GETFILTRED_ACTION_SPEAKER:
      return {
        ...state,
        filteredSpeakers: action.payload.data,
      };
    case ActionTypes.speaker.PAGINATE_SPEAKER:
      return {
        ...state,
        pagination: {
          TotalCount: action.payload.TotalCount,
          PageSize: action.payload.PageSize,
          CurrentPage: action.payload.CurrentPage,
          TotalPages: action.payload.TotalPages,
          HasNext: action.payload.HasNext,
          HasPrevious: action.payload.HasPrevious,
        },
      };
    case ActionTypes.speaker.HANDLE_ERROR_SPEAKER:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.speaker.HANDLE_VALIDATION_SPEAKER:
      return {
        ...state,
        validationMessage: action.payload,
      };
    case ActionTypes.speaker.SPEAKER_PAYMENT_SPEAKER:
      return {
        ...state,
        speakerPayment: action.payload,
      };
    case ActionTypes.speaker.GetSelectedMentor:
      return {
        ...state,
        selectedSpeaker: action.payload,
      };
    case ActionTypes.speaker.HandleRequest:
      return {
        ...state,
        loading: action.payload,
      };
    case ActionTypes.speaker.CleanList:
      return {
        ...state,
        speaker: {
          isFullTimer: false,
          detailInfo: { Education: '', Profession: '', Certificates: '' },
        },
        pagination: {
          TotalCount: '',
          PageSize: '',
          CurrentPage: '',
          TotalPages: '',
          HasNext: '',
          HasPrevious: '',
        },
        getById: {},
        getAll: [],
        filteredSpeakers: [],
        selectedSpeaker: [],
        speakerPayment: {},
      };
    default:
      return state;
  }
};

export default speakerReducer;
