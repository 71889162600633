import ActionTypes from '../actions/actionTypes.js';
import { initialState } from './initialState.js';

const careerReducer = (state = initialState.career, action) => {
  switch (action.type) {
    case ActionTypes.career.CREATE_ACTION_CAREER:
      return {
        ...state,
        career: initialState.career.career,
      };
    case ActionTypes.career.UPDATE_ACTION_CAREER:
      return {
        ...state,
        career: {},
        getById: [],
      };
    case ActionTypes.career.GETBYID_ACTION_CAREER:
      return {
        ...state,
        getById: action.payload.data,
      };
    case ActionTypes.career.GETALL_ACTION_CAREER:
      return {
        ...state,
        getAll: action.payload.data,
      };
    case ActionTypes.career.HANDLE_ERROR_CAREER:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.career.HANDLE_VALIDATION_CAREER:
      return {
        ...state,
        validationMessage: action.payload,
      };
    default:
      return state;
  }
};

export default careerReducer;
