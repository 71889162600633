import ActionTypes from '../actions/actionTypes.js';
import { initialState } from './initialState.js';

const becomeAPartnerReducer = (state = initialState.becomeAPartner, action) => {
  switch (action.type) {
    case ActionTypes.becomeAPartner.CREATE_ACTION_BECOMEAPARTNER:
      return {
        ...state,
        becomeAPartner: initialState.becomeAPartner.becomeAPartner,
      };
    case ActionTypes.becomeAPartner.UPDATE_ACTION_BECOMEAPARTNER:
      return {
        ...state,
        becomeAPartner: {},
        getById: {},
      };
    case ActionTypes.becomeAPartner.GETBYID_ACTION_BECOMEAPARTNER:
      return {
        ...state,
        getById: action.payload.data,
      };
    case ActionTypes.becomeAPartner.GETALL_ACTION_BECOMEAPARTNER:
      return {
        ...state,
        getAll: action.payload.data,
      };
    default:
      return state;
  }
};

export default becomeAPartnerReducer;
