import ActionTypes from '../actions/actionTypes.js';
import { initialState } from './initialState.js';

const speakerAvailabilityReducer = (state = initialState.speakerAvailability, action) => {
  switch (action.type) {
    case ActionTypes.speakerAvailability.Create:
      return {
        ...state,
        speakerAvailability: initialState.speakerAvailability.speakerAvailability,
      };
    case ActionTypes.speakerAvailability.Update:
      return {
        ...state,
        speakerAvailability: {},
      };
    case ActionTypes.speakerAvailability.GetAll:
      return {
        ...state,
        getAll: action.payload.data,
      };
    case ActionTypes.speakerAvailability.HandleError:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.speakerAvailability.HandleValidation:
      return {
        ...state,
        validationMessage: action.payload,
      };
    case ActionTypes.speakerAvailability.GetSelectedSpeakerDates:
      return {
        ...state,
        getSelectedAvaibilities: action.payload,
      };
    default:
      return state;
  }
};

export default speakerAvailabilityReducer;
