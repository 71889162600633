import ActionTypes from '../actions/actionTypes.js';
import { initialState } from './initialState.js';

const meetingSchemaReducer = (state = initialState.meetingSchema, action) => {
  switch (action.type) {
    case ActionTypes.meetingSchema.Create:
      return {
        ...state,
        meetingSchema: initialState.meetingSchema.meetingSchema,
      };
    case ActionTypes.meetingSchema.Update:
      return {
        ...state,
        meetingSchema: {},
        getById: [],
      };
    case ActionTypes.meetingSchema.GetById:
      return {
        ...state,
        getById: action.payload.data,
      };
    case ActionTypes.meetingSchema.GetAll:
      return {
        ...state,
        getAll: action.payload.data,
      };
    case ActionTypes.meetingSchema.HandleError:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.meetingSchema.GETALL_ACTION_MEETINGSCHEDULE:
      return {
        ...state,
        getAllSchedule: action.payload.data,
      };
    case ActionTypes.meetingSchema.GETALL_SESSION_COUNT_ACTION_MEETINGSCHEDULE:
      return {
        ...state,
        getAllSesionCount: action.payload.data,
      };
    case ActionTypes.meetingSchema.GET_SPEAKER_DROPDOWN_LIST_ACTION:
      return {
        ...state,
        dropdownList: action.payload,
      };
    case ActionTypes.meetingSchema.HandleValidation:
      return {
        ...state,
        validationMessage: action.payload,
      };
    case ActionTypes.meetingSchema.GETALL_ACTION_MEETING_BY_SPEAKER:
      return {
        ...state,
        getAllToday: action.payload.data,
      };
    case ActionTypes.meetingSchema.GetMeetingSpeakerSchedule:
      return {
        ...state,
        getSpeakerSchedule: action.payload.data,
      };
    case ActionTypes.meetingSchema.GET_MEETING_ATTENDEES:
      return {
        ...state,
        getMeetingAttendees: action.payload.data,
      };
    case ActionTypes.meetingSchema.ATTENDANCE_CHECK:
      return {
        ...state,
        attendanceCheck: action.payload.data,
      };
    case ActionTypes.meetingSchema.HandleRequest:
      return {
        ...state,
        loading: {
          ...state.loading,
          ...action.payload,
        },
      };
    case ActionTypes.meetingSchema.SessionHistory:
      return {
        ...state,
        sessionHistory: action.payload,
      };
    case ActionTypes.meetingSchema.CleanList:
      if (action.payload && typeof action.payload === 'string') {
        return {
          ...state,
          [action.payload]: initialState.meetingSchema[action.payload],
        };
      }
      return {
        ...initialState.meetingSchema,
      };
    default:
      return state;
  }
};

export default meetingSchemaReducer;
