import ActionTypes from '../actions/actionTypes.js';
import { initialState } from './initialState.js';

const sessionPackagesReducer = (state = initialState.sessionPackage, action) => {
  switch (action.type) {
    case ActionTypes.sessionPackage.CREATE_ACTION_SESSIONPACKAGE:
      return {
        ...state,
        sessionPackage: initialState.sessionPackage.sessionPackage,
      };
    case ActionTypes.sessionPackage.UPDATE_ACTION_SESSIONPACKAGE:
      return {
        ...state,
        sessionPackage: {},
        getById: {},
      };
    case ActionTypes.sessionPackage.GETBYID_ACTION_SESSIONPACKAGE:
      return {
        ...state,
        getById: action.payload.data,
      };
    case ActionTypes.sessionPackage.GETALL_ACTION_SESSIONPACKAGE:
      return {
        ...state,
        getAll: action.payload.data,
      };
    case ActionTypes.sessionPackage.HANDLE_ERROR_SESSIONPACKAGE:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.sessionPackage.HandleRequest:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default sessionPackagesReducer;
