import ActionTypes from '../actions/actionTypes.js';
import { initialState } from './initialState.js';

const zoneReducer = (state = initialState.zone, action) => {
  switch (action.type) {
    case ActionTypes.zone.CREATE_ACTION_ZONE:
      return {
        ...state,
        zone: initialState.zone.zone,
      };
    case ActionTypes.zone.UPDATE_ACTION_ZONE:
      return {
        ...state,
        zone: {},
        getById: {},
      };
    case ActionTypes.zone.GETBYID_ACTION_ZONE:
      return {
        ...state,
        getById: action.payload.data,
      };
    case ActionTypes.zone.GETALL_ACTION_ZONE:
      return {
        ...state,
        getAll: action.payload.data,
      };
    case ActionTypes.zone.HANDLE_ERROR_ZONE:
      return {
        ...state,
        isSuccess: action.payload,
      };
    default:
      return state;
  }
};

export default zoneReducer;
