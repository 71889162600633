import ActionTypes from '../actions/actionTypes.js';
import { initialState } from './initialState.js';

const companyReducer = (state = initialState.company, action) => {
  switch (action.type) {
    case ActionTypes.company.Create:
      return {
        ...state,
        company: initialState.company.company,
      };
    case ActionTypes.company.Update:
      return {
        ...state,
        company: {},
        getById: [],
      };
    case ActionTypes.company.GetById:
      return {
        ...state,
        getById: action.payload,
      };
    case ActionTypes.company.GetAll:
      return {
        ...state,
        getAll: action.payload,
      };
    case ActionTypes.company.GetCompanyAttendeesDropdown:
      return {
        ...state,
        getCompanyAttendeesDropdown: action.payload,
      };
    case ActionTypes.company.GetCompanyAttendees:
      return {
        ...state,
        getCompanyAttendees: action.payload,
      };
    case ActionTypes.company.GetCompanyManagers:
      return {
        ...state,
        getCompanyManagers: action.payload,
      };
    case ActionTypes.company.GetCompanyOrders:
      return {
        ...state,
        companyOrders: action.payload,
      };
    case ActionTypes.company.CompanyReporting:
      return {
        ...state,
        companyReporting: action.payload,
      };
    case ActionTypes.company.HandleError:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.company.HandleValidation:
      return {
        ...state,
        validationMessage: action.payload,
      };
    case ActionTypes.company.CleanList:
      return {
        ...state,
        company: {},
        getById: [],
        getAll: [],
        getCompanyAttendeesDropdown: [],
        getCompanyAttendees: [],
        getCompanyManagers: [],
        companyOrders: {},
        companyReporting: {},
      };
    default:
      return state;
  }
};

export default companyReducer;
