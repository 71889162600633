import ActionTypes from '../actions/actionTypes.js';
import { initialState } from './initialState.js';

const sessionCategoryReducer = (state = initialState.sessionCategory, action) => {
  switch (action.type) {
    case ActionTypes.sessionCategory.CREATE_ACTION_SESSIONCATEGORY:
      return {
        ...state,
        sessionCategory: initialState.sessionCategory.sessionCategory,
      };
    case ActionTypes.sessionCategory.UPDATE_ACTION_SESSIONCATEGORY:
      return {
        ...state,
        sessionCategory: {},
        getById: {},
      };
    case ActionTypes.sessionCategory.GETBYID_ACTION_SESSIONCATEGORY:
      return {
        ...state,
        getById: action.payload.data,
      };
    case ActionTypes.sessionCategory.GETALL_ACTION_SESSIONCATEGORY:
      return {
        ...state,
        getAll: action.payload.data,
      };
    case ActionTypes.sessionCategory.HANDLE_ERROR_SESSIONCATEGORY:
      return {
        ...state,
        isSuccess: action.payload,
      };
    default:
      return state;
  }
};

export default sessionCategoryReducer;
