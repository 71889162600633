import ActionTypes from '../actions/actionTypes.js';
import { initialState } from './initialState.js';

const orderReducer = (state = initialState.order, action) => {
  switch (action.type) {
    case ActionTypes.order.CREATE_ACTION_ORDER:
      return {
        ...state,
        order: initialState.order.order,
      };
    case ActionTypes.order.UPDATE_ACTION_ORDER_ITEM || ActionTypes.order.OrderItemUpdate:
      return {
        ...state,
        order: {},
        getById: [],
      };
    case ActionTypes.order.UpdateOrder:
      return {
        ...state,
        order: {},
        getById: [],
      };
    case ActionTypes.order.GETBYID_ACTION_ORDER:
      return {
        ...state,
        getById: action.payload.data,
      };
    case ActionTypes.order.GetOrderItemDetails:
      return {
        ...state,
        getById: action.payload.data,
      };
    case ActionTypes.order.GETALL_ACTION_ORDER:
      return {
        ...state,
        getAll: action.payload.data,
      };
    case ActionTypes.order.GetOrderDetails:
      return {
        ...state,
        getAll: action.payload.data,
      };
    case ActionTypes.order.HANDLE_ERROR_ORDER:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.order.EFT_CONTROL_ACTION_ORDER:
      return {
        ...state,
        eftList: action.payload.data,
      };
    case ActionTypes.order.GET_MYSUBSCRIPTION_PERIOD:
      return {
        ...state,
        mySubsPeriod: action.payload.data,
      };
    case ActionTypes.order.GET_ORDERS:
      return {
        ...state,
        activeOrders: action.payload.data,
      };
    case ActionTypes.order.GET_BASKET_ITEMS:
      return {
        ...state,
        basketItems: action.payload.data,
        isEmpty: action.payload.isEmpty,
      };
    case ActionTypes.order.GET_EARNED_FREESESSIONS:
      return {
        ...state,
        earnedFreeSessions: action.payload.data,
      };
    case ActionTypes.order.GET_GIFT_SESSIONS:
      return {
        ...state,
        giftSessions: action.payload.data,
      };
    case ActionTypes.order.HANDLE_VALIDATION_ORDER:
      return {
        ...state,
        validationMessage: action.payload,
      };
    case ActionTypes.order.SET_CALENDAR_TABLE:
      return {
        ...state,
        calendarTable: action.payload.data,
        isEmpty: action.payload.isEmpty,
      };
    case ActionTypes.order.GetMeetingsByOrder:
      return {
        ...state,
        getMeetingsByOrder: action.payload,
      };
    case ActionTypes.order.GetPayments:
      return {
        ...state,
        payments: action.payload,
      };
    case ActionTypes.order.TodaysTrialList:
      return {
        ...state,
        trialList: action.payload,
      };
    case ActionTypes.order.UpcomingTrialList:
      return {
        ...state,
        trialList: action.payload,
      };
    case ActionTypes.order.RenewOrderItemCalculation:
      return {
        ...state,
        renewOrderItem: action.payload,
      };
    case ActionTypes.order.CalculationRemainingSessionFee:
      return {
        ...state,
        calculationRemainingSessionFee: action.payload,
      };
    case ActionTypes.order.GetOrdersByAttendeeId:
      return {
        ...state,
        attendeeOrders: action.payload,
      };
    case ActionTypes.order.HandleRequest:
      return {
        ...state,
        loading: action.payload,
      };
    case ActionTypes.order.CleanList:
      return {
        ...state,
        order: {},
        calendarTable: [],
        getById: [],
        getAll: [],
        eftList: [],
        mySubsPeriod: [],
        activeOrders: [],
        earnedFreeSessions: [],
        giftSessions: [],
        getMeetingsByOrder: [],
        payments: [],
        trialList: [],
        renewOrderItem: [],
        calculationRemainingSessionFee: [],
      };
    case ActionTypes.resetStore.RESET_STORE:
      return {
        ...state,
        isSuccess: '',
        validationMessage: '',
        isEmpty: '',
      };
    default:
      return state;
  }
};

export default orderReducer;
