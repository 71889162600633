import ActionTypes from '../actions/actionTypes.js';
import { initialState } from './initialState.js';

const userOperationClaimReducer = (state = initialState.userOperationClaim, action) => {
  switch (action.type) {
    case ActionTypes.userOperationClaim.HANDLE_ERROR_USER_OPERATION_CLAIM_ACTION:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.userOperationClaim.HANDLE_VALIDATION_USER_OPERATION_CLAIM_ACTION:
      return {
        ...state,
        validationMessage: action.payload,
      };
    default:
      return state;
  }
};

export default userOperationClaimReducer;
