import ActionTypes from '../actions/actionTypes.js';
import { initialState } from './initialState.js';

const subscriptionReducer = (state = initialState.subscription, action) => {
  switch (action.type) {
    case ActionTypes.subscription.HandleError:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.subscription.GetAll:
      return {
        ...state,
        getAll: action.payload,
      };
    case ActionTypes.subscription.HandleValidation:
      return {
        ...state,
        validationMessage: action.payload,
      };
    case ActionTypes.subscription.HandleRequest:
      return {
        ...state,
        loading: {
          ...state.loading,
          ...action.payload,
        },
      };
    case ActionTypes.subscription.CleanList:
      if (action.payload && typeof action.payload === 'string') {
        return {
          ...state,
          [action.payload]: initialState.subscription[action.payload],
        };
      }
      return {
        ...initialState.subscription,
      };
    default:
      return state;
  }
};

export default subscriptionReducer;
