import ActionTypes from '../actions/actionTypes.js';
import { initialState } from './initialState.js';

const faqGroupReducer = (state = initialState.faqGroup, action) => {
  switch (action.type) {
    case ActionTypes.faqGroup.CREATE_ACTION_FAQGROUP:
      return {
        ...state,
        faqGroup: initialState.faqGroup.faqGroup,
      };
    case ActionTypes.faqGroup.UPDATE_ACTION_FAQGROUP:
      return {
        ...state,
        faqGroup: {},
        getById: {},
      };
    case ActionTypes.faqGroup.GETBYID_ACTION_FAQGROUP:
      return {
        ...state,
        getById: action.payload.data,
      };
    case ActionTypes.faqGroup.GETALL_ACTION_FAQGROUP:
      return {
        ...state,
        getAll: action.payload.data,
      };
    case ActionTypes.faqGroup.HandleRequest:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

export default faqGroupReducer;
