import ActionTypes from '../actions/actionTypes.js';
import { initialState } from './initialState.js';

const meetingAttendeeReducer = (state = initialState.meetingAttendee, action) => {
  switch (action.type) {
    case ActionTypes.meetingAttendee.HandleError:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.meetingAttendee.HandleValidation:
      return {
        ...state,
        validationMessage: action.payload,
      };
    default:
      return state;
  }
};
export default meetingAttendeeReducer;
