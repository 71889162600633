import ActionTypes from '../actions/actionTypes.js';
import { initialState } from './initialState.js';

const bannerReducer = (state = initialState.banner, action) => {
  switch (action.type) {
    case ActionTypes.banner.Create:
      return {
        ...state,
        banner: initialState.banner.banner,
      };
    case ActionTypes.banner.Update:
      return {
        ...state,
        banner: initialState.banner.banner,
        getById: {},
      };
    case ActionTypes.banner.GetAll:
      return {
        ...state,
        getAll: action.payload,
      };
    case ActionTypes.banner.GetById:
      return {
        ...state,
        getById: action.payload,
      };
    case ActionTypes.banner.GetActiveBannerByZone:
      return {
        ...state,
        getActiveBannerByZone: action.payload,
      };
    case ActionTypes.banner.HandleError:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.banner.HandleValidation:
      return {
        ...state,
        validationMessage: action.payload,
      };
    case ActionTypes.banner.HandleRequest:
      return {
        ...state,
        loading: action.payload,
      };
    case ActionTypes.banner.CleanList:
      return {
        ...state,
        banner: initialState.banner.banner,
        getById: {},
        getAll: [],
        getActiveBannerByZone: {},
      };
    default:
      return state;
  }
};

export default bannerReducer;
