import ActionTypes from '../actions/actionTypes.js';
import { initialState } from './initialState.js';

const timesPerWeekReducer = (state = initialState.timesPerWeek, action) => {
  switch (action.type) {
    case ActionTypes.timesPerWeek.CREATE_ACTION_TIMESPERWEEK:
      return {
        ...state,
        timesPerWeek: initialState.timesPerWeek.timesPerWeek,
      };
    case ActionTypes.timesPerWeek.UPDATE_ACTION_TIMESPERWEEK:
      return {
        ...state,
        timesPerWeek: {},
        getById: {},
      };
    case ActionTypes.timesPerWeek.GETBYID_ACTION_TIMESPERWEEK:
      return {
        ...state,
        getById: action.payload.data,
      };
    case ActionTypes.timesPerWeek.GETALL_ACTION_TIMESPERWEEK:
      return {
        ...state,
        getAll: action.payload.data,
      };
    case ActionTypes.timesPerWeek.HANDLE_ERROR_TIMESPERWEEK:
      return {
        ...state,
        isSuccess: action.payload,
      };
    default:
      return state;
  }
};

export default timesPerWeekReducer;
