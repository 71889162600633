import ActionTypes from '../actions/actionTypes.js';
import { initialState } from './initialState.js';

const wheelEventReducer = (state = initialState.wheelEvent, action) => {
  switch (action.type) {
    case ActionTypes.wheelEvent.GetActiveWheel:
      return {
        ...state,
        getById: action.payload,
      };
    case ActionTypes.wheelEvent.HandleError:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.wheelEvent.HandleValidation:
      return {
        ...state,
        validationMessage: action.payload,
      };
    default:
      return state;
  }
};

export default wheelEventReducer;
