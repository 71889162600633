import {
  CurrencyUnit,
  DayOfWeeks,
  Gender,
  KidsLangLevel,
  Language,
  LanguageLevelGroup,
  LearningPurpose,
  MeetingAttendeeStatus,
  MeetingStatus,
  Native,
  NotificationCategory,
  NotificationType,
  OrderStatus,
  PaymentStatus,
  PaymentType,
  TypeOfService,
  UserArea,
  storageKeys,
} from '../../../constants/Enum.jsx';
import { setAreaDetails } from '../../../services/apiServices/authServices.js';

const getToken = () => localStorage.getItem(storageKeys.token);
const getPath = () => sessionStorage.getItem(storageKeys.path);
const token = getToken();
const path = getPath();

let claims = [];
token
  ? (claims = JSON.parse(window?.atob(token?.split('.')[1]))[
      'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
    ])
  : (claims = '');
let id = '';
token
  ? (id = JSON.parse(window?.atob(token?.split('.')[1]))[
      'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
    ])
  : (id = '');
let version = '';
token ? (version = JSON.parse(window?.atob(token?.split('.')[1]))['website']) : (version = '');
let area = 'Online';
token ? (area = JSON.parse(window?.atob(token?.split('.')[1]))['typ']) : (area = 'Online');

const userArea = sessionStorage.getItem(storageKeys.userArea) ?? setAreaDetails(area).myBoard;
sessionStorage.setItem(storageKeys.userArea, userArea);

let enumValue;
let oboardPath;
switch (userArea) {
  case 'Online':
  case 'Çevrimiçi':
    enumValue = 0;
    oboardPath = '/online';
    break;
  case 'Cafe':
  case 'Kafe':
    enumValue = 1;
    oboardPath = '/cafe';
    break;
  case 'Kids':
  case 'Çocuklar':
    enumValue = 2;
    oboardPath = '/kids';
    break;
  case 'Company':
  case 'Şirket':
    enumValue = 3;
    oboardPath = '/company';
    break;
  default:
    enumValue = 4;
    oboardPath = '';
    break;
}

const storedCurrency =
  localStorage.getItem(storageKeys.timeZone) !== null && localStorage.getItem(storageKeys.timeZone) !== 'undefined'
    ? JSON.parse(localStorage.getItem(storageKeys.timeZone))
    : { code: 949, name: 'TL', symbol: '₺', timeZone: 'Europe/Istanbul' };

export const initialState = {
  auth: {
    auth: token || '',
    showLoading: false,
    claim: claims || [],
    currentUser: id,
    validationMessage: '',
    isSuccess: '',
    oAuth: '',
    version: version || '',
    path: path || '',
  },
  language: {
    language: localStorage.getItem(storageKeys.languge),
    flag: localStorage.getItem(storageKeys.languge),
  },
  screensize: {
    size: window.innerWidth < 1024 ? true : false,
    setted: true,
  },
  career: {
    career: {},
    getById: [],
    getAll: [],
    validationMessage: '',
    isSuccess: '',
  },
  companyForm: {
    companyForm: { reviewed: false },
    getById: [],
    getAll: [],
    validationMessage: '',
    isSuccess: '',
    loading: {},
  },
  faqGroup: {
    faqGroup: {},
    getById: [],
    getAll: [],
    loading: false,
  },
  faq: {
    faq: {},
    getById: [],
    getAll: [],
    loading: false,
  },
  user: {
    user: {},
    getById: {},
    getAll: [],
    userPreferences: [],
    selfUpdate: '',
    isSuccess: '',
    validationMessage: '',
    loading: {},
  },
  staticDatas: {
    LearningPurpose: LearningPurpose,
    SessionLevel: [],
    TypeOfService: TypeOfService,
    Countries: [],
    KidsLangLevel: KidsLangLevel,
    Language: Language,
    CountryCode: [],
    CurrencyUnit: CurrencyUnit,
    DayOfWeeks: DayOfWeeks,
    LanguageLevels: LanguageLevelGroup,
    MeetingLanguageLevels: [],
    PaymentStatus: PaymentStatus,
    PaymentType: PaymentType,
    UserArea: UserArea,
    Native: Native,
    Gender: Gender,
    NotificationType: NotificationType,
    NotificationCategory: NotificationCategory,
    OrderStatus: OrderStatus,
    MeetingStatus: MeetingStatus,
    MeetingAttendeeStatus: MeetingAttendeeStatus,
  },
  speaker: {
    speaker: {
      isFullTimer: false,
      detailInfo: { Education: '', Profession: '', Certificates: '' },
    },
    getById: {},
    getAll: [],
    filteredSpeakers: [],
    selectedSpeaker: [],
    pagination: {
      TotalCount: '',
      PageSize: '',
      CurrentPage: '',
      TotalPages: '',
      HasNext: '',
      HasPrevious: '',
    },
    validationMessage: '',
    isSuccess: '',
    speakerPayment: {},
    loading: false,
  },

  zone: {
    zone: {},
    getById: [],
    getAll: [],
    isSuccess: '',
  },
  test_price_studies: {
    zone: {
      zone: {},
      getById: [],
      getAll: [],
    },
  },
  onboard: {
    onboard: userArea,
    enum: enumValue,
    path: oboardPath,
  },
  couponCode: {
    couponCode: {},
    getByName: {},
    getById: [],
    getAll: [],
    isSuccess: '',
    validationMessage: '',
  },
  timesPerWeek: {
    timesPerWeek: {},
    getById: [],
    getAll: [],
    isSuccess: '',
  },
  sessionPackage: {
    sessionPackage: {},
    getById: [],
    getAll: [],
    isSuccess: '',
    loading: false,
  },
  attendee: {
    attendee: {},
    getById: [],
    getAll: [],
    filteredAttendee: [],
    attendeeEvaluation: [],
    refCode: '',
    isSuccess: '',
    validationMessage: '',
  },
  attendeeFreeze: {
    attendeeFreeze: {},
    getById: [],
    getAll: [],
  },
  branchFreeze: {
    branchFreeze: {},
    getById: [],
    getAll: [],
    validationMessage: '',
    isSuccess: '',
  },
  manager: {
    manager: {},
    getById: [],
    getAll: [],
    isSuccess: '',
    validationMessage: '',
  },
  location: {
    location: {},
    getById: [],
    getAll: [],
    branches: [],
    isSuccess: '',
    validationMessage: '',
    loading: false,
  },
  ageInterval: {
    ageInterval: {},
    getById: [],
    getAll: [],
    isSuccess: '',
  },
  timesPerMonth: {
    timesPerMonth: {},
    getById: [],
    getAll: [],
    isSuccess: '',
  },
  sessionCategory: {
    sessionCategory: {},
    getById: [],
    getAll: [],
    isSuccess: '',
  },
  meetingSchema: {
    meetingSchema: { privateCapacity: 0, companyId: 1 },
    getById: [],
    getAll: [],
    getAllSchedule: [],
    getAllSesionCount: [],
    isSuccess: '',
    validationMessage: '',
    dropdownList: [],
    getAllToday: [],
    getSpeakerSchedule: [],
    getMeetingAttendees: [],
    attendanceCheck: [],
    sessionHistory: [],
    loading: {},
  },
  order: {
    order: {},
    calendarTable: { selectionOptions: [] },
    getById: [],
    getAll: [],
    eftList: [],
    mySubsPeriod: [],
    activeOrders: [],
    basketItems: [],
    isEmpty: '',
    earnedFreeSessions: [],
    giftSessions: [],
    getMeetingsByOrder: [],
    payments: [],
    trialList: [],
    renewOrderItem: [],
    calculationRemainingSessionFee: [],
    attendeeOrders: [],
    isSuccess: '',
    validationMessage: '',
    showLoading: false,
    loading: false,
  },
  becomeAPartner: {
    becomeAPartner: {},
    getById: [],
    getAll: [],
  },
  banner: {
    banner: { zones: '1' },
    getById: {},
    getAll: [],
    getActiveBannerByZone: {},
    validationMessage: '',
    isSuccess: '',
    loading: false,
  },
  userPackage: {
    packageData: {},
    isPackageComplete: false,
  },
  calendar: {
    getAllFiltered: {},
    getBySpeakerId: [],
    getAll: [],
    getCellDatas: [],
    getMeetings: [],
    isSuccess: '',
    validationMessage: '',
    loading: {},
  },
  speakerAvailability: {
    speakerAvailability: {},
    getAll: [],
    isSuccess: '',
    validationMessage: '',
    getSelectedAvaibilities: [],
  },
  classroom: {
    classroomMessage: {},
    getPrivateRoomMembers: [],
    getPrivateMessages: [],
    getGroupMessages: [],
    getGroupRoomMembers: [],
    isSuccess: '',
    validationMessage: '',
  },
  preference: {
    isSuccess: '',
    currenyOptions: [],
    currencyUnit: token === null ? storedCurrency : {},
    levelGroup: JSON.parse(localStorage.getItem(storageKeys.levelGroup)) ?? {
      adult: 'Quintet',
      kids: 'KidsLangLevel',
    },
    loading: false,
  },
  subscription: {
    getById: {},
    getAll: [],
    isSuccess: '',
    validationMessage: '',
    loading: {},
  },
  userOperationClaim: {
    isSuccess: '',
    validationMessage: '',
  },
  payment: {
    installment: {},
    callBackContent: '',
    resultMessage: '',
    isSuccess: '',
    validationMessage: '',
    loading: {},
  },
  meetingAttendee: { isSuccess: '', validationMessage: '' },
  filter: {
    filter: {},
    createData: [],
    forBadge: [],
  },
  feedback: {
    getAll: [],
    isSuccess: '',
    validationMessage: '',
  },
  speakerEffort: {
    getAll: [],
    getDetail: [],
    isSuccess: '',
    validationMessage: '',
  },
  lead: {
    getById: [],
    getAll: [],
    isSuccess: '',
    validationMessage: '',
  },
  price: {
    calculatedPrices: [],
    isSuccess: '',
    validationMessage: '',
    loading: {},
  },
  company: {
    company: {},
    getById: [],
    getAll: [],
    getCompanyAttendeesDropdown: [],
    getCompanyAttendees: [],
    getCompanyManagers: [],
    companyOrders: {},
    companyReporting: {},
    isSuccess: '',
    validationMessage: '',
  },
  campaign: {
    campaign: {
      zones: '1',
      campaignImages: [
        {
          id: 1,
          imageName: '',
          imageSrc: '',
          imageExtension: '',
        },
      ],
    },
    getById: {},
    getAll: [],
    dashboardBanner: {},
    validationMessage: '',
    isSuccess: '',
  },
  reporting: {
    getAll: [],
    isSuccess: '',
    validationMessage: '',
    loading: {},
  },
  wheelEvent: {
    getById: [],
    isSuccess: '',
    validationMessage: '',
    loading: {},
  },
  meeting: {
    meeting: {},
    getById: [],
    getAll: [],
    isSuccess: '',
    validationMessage: '',
    loading: {},
    getMeetingsBySchemaId: [],
  },
};
