import ActionTypes from '../actions/actionTypes.js';
import { initialState } from './initialState.js';

const attendeeFreezeReducer = (state = initialState.attendeeFreeze, action) => {
  switch (action.type) {
    case ActionTypes.attendeeFreeze.CREATE_ACTION_ATTENDEEFREEZE:
      return {
        ...state,
        attendeeFreeze: initialState.attendeeFreeze.attendeeFreeze,
      };
    case ActionTypes.attendeeFreeze.UPDATE_ACTION_ATTENDEEFREEZE:
      return {
        ...state,
        attendeeFreeze: {},
        getById: {},
      };
    case ActionTypes.attendeeFreeze.GETBYID_ACTION_ATTENDEEFREEZE:
      return {
        ...state,
        getById: action.payload.data,
      };
    case ActionTypes.attendeeFreeze.GETALL_ACTION_ATTENDEEFREEZE:
      return {
        ...state,
        getAll: action.payload.data,
      };
    default:
      return state;
  }
};

export default attendeeFreezeReducer;
