import ActionTypes from '../actions/actionTypes.js';
import { initialState } from './initialState.js';

const ageIntervalReducer = (state = initialState.ageInterval, action) => {
  switch (action.type) {
    case ActionTypes.ageInterval.CREATE_ACTION_AGEINTERVAL:
      return {
        ...state,
        ageInterval: initialState.ageInterval.ageInterval,
      };
    case ActionTypes.ageInterval.UPDATE_ACTION_AGEINTERVAL:
      return {
        ...state,
        ageInterval: {},
        getById: {},
      };
    case ActionTypes.ageInterval.GETBYID_ACTION_AGEINTERVAL:
      return {
        ...state,
        getById: action.payload.data,
      };
    case ActionTypes.ageInterval.GETALL_ACTION_AGEINTERVAL:
      return {
        ...state,
        getAll: action.payload.data,
      };
    case ActionTypes.ageInterval.HANDLE_ERROR_AGEINTERVAL:
      return {
        ...state,
        isSuccess: action.payload,
      };
    default:
      return state;
  }
};

export default ageIntervalReducer;
