import ActionTypes from '../actions/actionTypes.js';
import { initialState } from './initialState.js';

const branchFreezeReducer = (state = initialState.branchFreeze, action) => {
  switch (action.type) {
    case ActionTypes.branchFreeze.Create:
      return {
        ...state,
        branchFreeze: initialState.branchFreeze.branchFreeze,
      };
    case ActionTypes.branchFreeze.Update:
      return {
        ...state,
        branchFreeze: {},
        getById: {},
      };
    case ActionTypes.branchFreeze.GetById:
      return {
        ...state,
        getById: action.payload,
      };
    case ActionTypes.branchFreeze.GetAll:
      return {
        ...state,
        getAll: action.payload.data,
      };
    case ActionTypes.branchFreeze.HandleError:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.branchFreeze.HandleValidation:
      return {
        ...state,
        validationMessage: action.payload,
      };
    case ActionTypes.branchFreeze.CleanList:
      if (action.payload && typeof action.payload === 'string') {
        return {
          ...state,
          [action.payload]: initialState.branchFreeze[action.payload],
        };
      }
      return {
        ...initialState.branchFreeze,
      };
    default:
      return state;
  }
};

export default branchFreezeReducer;
