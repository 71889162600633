import ActionTypes from '../actions/actionTypes.js';
import { initialState } from './initialState.js';

const companyFormReducer = (state = initialState.companyForm, action) => {
  switch (action.type) {
    case ActionTypes.companyForm.Create:
      return {
        ...state,
        companyForm: initialState.companyForm.companyForm,
      };
    case ActionTypes.companyForm.Update:
      return {
        ...state,
        companyForm: {},
        getById: {},
      };
    case ActionTypes.companyForm.GetById:
      return {
        ...state,
        getById: action.payload,
      };
    case ActionTypes.companyForm.GetAll:
      return {
        ...state,
        getAll: action.payload,
      };
    case ActionTypes.companyForm.HandleError:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.companyForm.HandleValidation:
      return {
        ...state,
        validationMessage: action.payload,
      };
    case ActionTypes.companyForm.HandleRequest:
      return {
        ...state,
        loading: {
          ...state.loading,
          ...action.payload,
        },
      };
    case ActionTypes.companyForm.CleanList:
      if (action.payload && typeof action.payload === 'string') {
        return {
          ...state,
          [action.payload]: initialState.companyForm[action.payload],
        };
      }
      return {
        ...initialState.companyForm,
      };
    default:
      return state;
  }
};

export default companyFormReducer;
