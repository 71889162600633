import ActionTypes from '../actions/actionTypes.js';
import { initialState } from './initialState.js';

const timesPerMonthReducer = (state = initialState.timesPerMonth, action) => {
  switch (action.type) {
    case ActionTypes.timesPerMonth.CREATE_ACTION_TIMESPERMONTH:
      return {
        ...state,
        timesPerMonth: initialState.timesPerMonth.timesPerMonth,
      };
    case ActionTypes.timesPerMonth.UPDATE_ACTION_TIMESPERMONTH:
      return {
        ...state,
        timesPerMonth: {},
        getById: {},
      };
    case ActionTypes.timesPerMonth.GETBYID_ACTION_TIMESPERMONTH:
      return {
        ...state,
        getById: action.payload.data,
      };
    case ActionTypes.timesPerMonth.GETALL_ACTION_TIMESPERMONTH:
      return {
        ...state,
        getAll: action.payload.data,
      };
    case ActionTypes.timesPerMonth.HANDLE_ERROR_TIMESPERMONTH:
      return {
        ...state,
        isSuccess: action.payload,
      };
    default:
      return state;
  }
};

export default timesPerMonthReducer;
