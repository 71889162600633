import ActionTypes from '../actions/actionTypes.js';
import { initialState } from './initialState.js';

const calendarReducer = (state = initialState.calendar, action) => {
  switch (action.type) {
    case ActionTypes.calendar.GET_ACTION_CALENDAR_DATA_BY_SPEAKERID:
      return {
        ...state,
        getBySpeakerId: action.payload,
      };
    case ActionTypes.calendar.GETALL_ACTION_CALENDAR_DATA:
      return {
        ...state,
        getAll: action.payload,
      };
    case ActionTypes.calendar.GetCalendarData:
      return {
        ...state,
        getAllFiltered: action.payload,
      };
    case ActionTypes.calendar.GetCellDetails:
      return {
        ...state,
        getCellDatas: action.payload,
      };
    case ActionTypes.calendar.HandleValidation:
      return {
        ...state,
        validationMessage: action.payload,
      };
    case ActionTypes.calendar.HandleError:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.calendar.handleLoading:
      return {
        ...state,
        showLoading: action.payload,
      };
    case ActionTypes.calendar.getMeetingAttendees:
      return {
        ...state,
        getMeetings: action.payload,
      };
    case ActionTypes.calendar.HandleRequest:
      return {
        ...state,
        loading: {
          ...state.loading,
          ...action.payload,
        },
      };
    case ActionTypes.calendar.CleanList:
      if (action.payload && typeof action.payload === 'string') {
        return {
          ...state,
          [action.payload]: initialState.calendar[action.payload],
        };
      }
      return {
        ...initialState.calendar,
      };
    default:
      return state;
  }
};

export default calendarReducer;
