import ActionTypes from '../actions/actionTypes.js';
import { initialState } from './initialState.js';

const preferenceReducer = (state = initialState.preference, action) => {
  switch (action.type) {
    case ActionTypes.preference.CURRENCY_CHOSEN:
      return {
        ...state,
        isSuccess: action.payload.isSuccess,
        currencyUnit: action.payload.data,
      };
    case ActionTypes.preference.GET_CURRENCY_OPTION:
      return {
        ...state,
        currenyOptions: action.payload,
      };
    case ActionTypes.preference.GET_LEVEL_GROUP:
      return {
        ...state,
        levelGroup: action.payload,
      };
    case ActionTypes.preference.HandleRequest:
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};
export default preferenceReducer;
