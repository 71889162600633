import ActionTypes from '../actions/actionTypes.js';
import { initialState } from './initialState.js';

const filterReducer = (state = initialState.filter, action) => {
  switch (action.type) {
    case ActionTypes.filter.HOLD_FILTER:
      return {
        ...state,
        filter: action.payload.data,
      };
    case ActionTypes.filter.HOLD_CREATE_DATA:
      return {
        ...state,
        createData: action.payload.data,
      };
    case ActionTypes.filter.HOLD_FOR_BADGE:
      return {
        ...state,
        forBadge: action.payload.data,
      };
    case ActionTypes.filter.RESET_STATE:
      return {
        ...state,
        createData: initialState.filter.createData,
        forBadge: initialState.filter.forBadge,
        filter: initialState.filter.filter,
      };
    default:
      return state;
  }
};
export default filterReducer;
