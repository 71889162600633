import ActionTypes from '../actions/actionTypes.js';
import { initialState } from './initialState.js';

const langReducer = (state = initialState.language, action) => {
  switch (action.type) {
    case ActionTypes.lang.LANGUAGE_CHOSEN:
      return {
        ...state,
        language: action.payload.newLang + ',' + action.payload.longLang,
      };
    case ActionTypes.lang.SET_FLAG:
      return {
        ...state,
        flag: action.payload,
      };
    default:
      return state;
  }
};
export default langReducer;
