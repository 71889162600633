import ActionTypes from '../actions/actionTypes.js';
import { initialState } from './initialState.js';

const priceReducer = (state = initialState.price, action) => {
  switch (action.type) {
    case ActionTypes.price.CalculatedPrices:
      return {
        ...state,
        calculatedPrices: action.payload,
      };
    case ActionTypes.price.HandleError:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.price.HandleValidation:
      return {
        ...state,
        validationMessage: action.payload,
      };
    case ActionTypes.price.HandleRequest:
      return {
        ...state,
        loading: {
          ...state.loading,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default priceReducer;
