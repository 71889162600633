import ActionTypes from '../actions/actionTypes.js';
import { initialState } from './initialState.js';

const authReducer = (state = initialState.auth, action) => {
  switch (action.type) {
    case ActionTypes.auth.LOGIN_ACTION:
      return {
        ...state,
        auth: action.payload.token,
        claim: action.payload.claims,
        currentUser: action.payload.id,
        showLoading: action.payload.showLoading,
        version: action.payload.version,
        path: action.payload.path,
      };
    case ActionTypes.auth.SIGNUP_ACTION:
      return {
        ...state,
        auth: action.payload.token,
        claim: action.payload.claim,
        currentUser: action.payload.id,
        showLoading: action.payload.showLoading,
        version: action.payload.version,
        path: action.payload.path,
      };
    case ActionTypes.auth.LOGOUT_ACTION:
      return {
        ...state,
        auth: '',
        claim: [],
        currentUser: '',
      };
    case ActionTypes.auth.LOADING_TOGGLE_ACTION:
      return {
        ...state,
        showLoading: action.payload,
      };
    case ActionTypes.auth.REFRESH_TOKEN:
      return {
        ...state,
        auth: action.payload,
        showLoading: false,
      };
    case ActionTypes.auth.OAUTH_ACTION:
      return {
        ...state,
        auth: action.payload.token,
        claim: action.payload.claim,
        currentUser: action.payload.id,
        showLoading: action.payload.showLoading,
        oAuth: action.payload.oAuth,
        version: action.payload.version,
        path: action.payload.path,
      };
    case ActionTypes.auth.HANDLE_VALIDATION_AUTH:
      return {
        ...state,
        validationMessage: action.payload,
      };
    case ActionTypes.auth.HANDLE_ERROR_AUTH:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.resetStore.RESET_STORE:
      return {
        ...state,
        isSuccess: '',
        validationMessage: '',
      };
    default:
      return state;
  }
};

export default authReducer;
