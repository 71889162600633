import { combineReducers } from 'redux';

import ageIntervalReducer from './ageIntervalReducer.js';
import attendeeFreezeReducer from './attendeeFreezeReducer.js';
import attendeeReducer from './attendeeReducer.js';
import authReducer from './authReducer.js';
import bannerReducer from './bannerReducer.js';
import becomeAPartnerReducer from './becomeAPartnerReducer.js';
import branchFreezeReducer from './branchFreezeReducer.js';
import calendarReducer from './calendarReducer.js';
import campaignReducer from './campaignReducer.js';
import careerReducer from './careerReducer.js';
import classroomReducer from './classroomReducer.js';
import companyFormReducer from './companyFormReducer.js';
import companyReducer from './companyReducer.js';
import couponCodesReducer from './couponCodesReducer.js';
import faqGroupReducer from './faqGroupReducer.js';
import faqReducer from './faqReducer.js';
import feedbackReducer from './feedbackReducer.js';
import filterReducer from './filterReducer.js';
import langReducer from './langReducer.js';
import leadReducer from './leadReducer.js';
import locationReducer from './locationReducer.js';
import managerReducer from './managerReducer.js';
import meetingAttendeeReducer from './meetingAttendeeReducer.js';
import meetingReducer from './meetingReducer.js';
import meetingSchemaReducer from './meetingSchemaReducer.js';
import onboardReducer from './onboardReducer.js';
import orderReducer from './orderReducer.js';
import paymentReducer from './paymentReducer.js';
import preferenceReducer from './preferenceReducer.js';
import priceReducer from './priceReducer.js';
import reportingReducer from './reportingReducer.js';
import screenReducer from './screenReducer.js';
import sessionCategoryReducer from './sessionCategoryReducer.js';
import sessionPackageReducer from './sessionPackageReducer.js';
import speakerAvailabilityReducer from './speakerAvailabilityReducer.js';
import speakerEffortReducer from './speakerEffortReducer.js';
import speakerReducer from './speakerReducer.js';
import staticDataReducer from './staticDataReducer.js';
import subscriptionReducer from './subscriptionReducer.js';
import timesPerMonthReducer from './timesPerMonthReducer.js';
import timesPerWeekReducer from './timesPerWeekReducer.js';
import userOperationClaimReducer from './userOperationClaimReducer.js';
import userPackageReducer from './userPackageReducer.js';
import userReducer from './userReducer.js';
import wheelEventReducer from './wheelEventReducer.js';
import zoneReducer from './zoneReducer.js';

const rootReducer = (state, action) => {
  return combineReducers({
    auth: authReducer,
    screen: screenReducer,
    lang: langReducer,
    career: careerReducer,
    companyForm: companyFormReducer,
    faqGroup: faqGroupReducer,
    faq: faqReducer,
    user: userReducer,
    staticData: staticDataReducer,
    speaker: speakerReducer,
    onboard: onboardReducer,
    couponCode: couponCodesReducer,
    timesPerWeek: timesPerWeekReducer,
    sessionPackage: sessionPackageReducer,
    zone: zoneReducer,
    attendee: attendeeReducer,
    attendeeFreeze: attendeeFreezeReducer,
    branchFreeze: branchFreezeReducer,
    manager: managerReducer,
    location: locationReducer,
    ageInterval: ageIntervalReducer,
    timesPerMonth: timesPerMonthReducer,
    sessionCategory: sessionCategoryReducer,
    meetingSchema: meetingSchemaReducer,
    order: orderReducer,
    becomeAPartner: becomeAPartnerReducer,
    banner: bannerReducer,
    userPackage: userPackageReducer,
    calendar: calendarReducer,
    speakerAvailability: speakerAvailabilityReducer,
    classroom: classroomReducer,
    preference: preferenceReducer,
    subscription: subscriptionReducer,
    userOperationClaim: userOperationClaimReducer,
    payment: paymentReducer,
    holdFilter: filterReducer,
    meetingAttendee: meetingAttendeeReducer,
    feedback: feedbackReducer,
    speakerEffort: speakerEffortReducer,
    lead: leadReducer,
    price: priceReducer,
    company: companyReducer,
    campaign: campaignReducer,
    reporting: reportingReducer,
    wheelEvent: wheelEventReducer,
    meeting: meetingReducer,
  })(state, action);
};

export default rootReducer;
