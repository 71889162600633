import ActionTypes from '../actions/actionTypes.js';
import { initialState } from './initialState.js';

const reportingReducer = (state = initialState.reporting, action) => {
  switch (action.type) {
    case ActionTypes.reporting.GetAll:
      return {
        ...state,
        getAll: action.payload,
      };
    case ActionTypes.reporting.HandleError:
      return {
        ...state,
        isSuccess: action.payload,
      };
    case ActionTypes.reporting.HandleValidation:
      return {
        ...state,
        validationMessage: action.payload,
      };
    case ActionTypes.reporting.CleanList:
      if (action.payload && typeof action.payload === 'string') {
        return {
          ...state,
          isSuccess: '',
          validationMessage: '',
          [action.payload]: initialState.reporting[action.payload],
        };
      }
      return {
        ...initialState.reporting,
      };
    default:
      return state;
  }
};

export default reportingReducer;
